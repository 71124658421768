// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   headline_level -> option / Überschriftengröße
//   subheadline -> text / Unterzeile
//   text -> markdown
// end of automatic

import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import TextReveal from "@yumgmbh/gatsby-theme-yum-components/src/components/reveals/TextReveal"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import ButtonGroup from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/ButtonGroup"

const TextBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockWidth="container"
      showHeadline="true"
      blockDirection="vertical"
      className=""
    >
      <div className="-mx-grid">
        <TextReveal className="lg:w-1/2 px-grid">
          <Richtext
            flow={block.flow}
            text={block.text}
            className={` ${
              block.text_size === "text-lg" ? block.text_size : ""
            }`}
          />
          <ButtonGroup className="mt-8" buttons={block.buttons} />
        </TextReveal>
      </div>
    </BlockWrapper>
  )
}

export default TextBlock
