import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faAt, faFax } from "@fortawesome/pro-solid-svg-icons"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const TeamCard = ({ item }) => {
  return (
    <Link className="block" link={item.link}>
      <div className="">
        {item.image && (
          <div className="mb-4 ">
            <Image
              image={item.image}
              className="w-full h-auto"
              aspectRatio="1by1"
            />
          </div>
        )}
        <div className="">
          {item.name && (
            <span className="block font-bold text-primary">{item.name}</span>
          )}
          {item.description && (
            <span className="block mb-2">{item.description}</span>
          )}
          {item.tel && (
            <a
              className="block mb-2 text-primary hover:text-primary-hover"
              href={"tel:" + item.tel}
            >
              <FontAwesomeIcon icon={faPhoneAlt} size="1x" className="mr-1" />
              {item.tel}
            </a>
          )}
          {item.fax && (
            <span className="block mb-2">
              <FontAwesomeIcon icon={faFax} size="1x" className="mr-1" />
              {item.fax}
            </span>
          )}
          {item.email && (
            <a
              className="block mb-2 text-primary hover:text-primary-hover"
              href={"mailto:" + item.email}
            >
              <FontAwesomeIcon icon={faAt} size="1x" className="mr-1" />
              {item.email}
            </a>
          )}
          <Richtext text={item.text} />
        </div>
      </div>
    </Link>
  )
}

export default TeamCard
