import React from "react"
import Slider from "react-slick"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import { CaretLeft16, CaretRight16 } from "@carbon/icons-react"

const SliderGalleryBlock = ({ block }) => {
  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretRight16 />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <CaretLeft16 />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    mobileFirst: true,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,
  }

  return (
    <BlockWrapper block={block} showHeadline="true">
      <div className="pt-8 -mx-grid">
        <Slider {...settings}>
          {block.image_items &&
            block.image_items.length > 0 &&
            block.image_items.map((item, index) => (
              <figure className="px-grid" key={index}>
                <div
                  className="relative w-full"
                  style={{ paddingBottom: "66.6666%" }}
                  key={index}
                >
                  <div className="absolute top-0 left-0 block w-full h-full text-center">
                    <div className="block w-full h-full bg-gray-200 ">
                      <Image
                        image={item.image}
                        className="object-contain w-full h-full"
                      />
                    </div>
                  </div>
                </div>
                {(item.caption?.length > 0 ||
                  item.image?.title?.length > 0 ||
                  item.image?.copyright?.length > 0) && (
                  <div className={`-mx-grid`}>
                    <figcaption className="w-full max-w-screen-lg mx-auto my-4 px-grid">
                      {item.caption?.length > 0 ? (
                        <span className="mr-4">{item.caption}</span>
                      ) : item.image?.title?.length > 0 ? (
                        <span className="mr-4">{item.image.title}</span>
                      ) : (
                        ""
                      )}
                      {item.image?.copyright?.length > 0 && (
                        <small>© {item.image.copyright}</small>
                      )}
                    </figcaption>
                  </div>
                )}
              </figure>
            ))}
        </Slider>
      </div>
    </BlockWrapper>
  )
}

export default SliderGalleryBlock
