import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { LocationFilled16, Compass16 } from "@carbon/icons-react"

const DealerSearch = ({ activeAddress, setQueryString, setSearchMode }) => {
  const [value, setValue] = useState(activeAddress)

  const { t } = useTranslation()

  const handleInput = (event) => setValue(event.target.value)

  useEffect(() => {
    if (activeAddress) {
      setValue(activeAddress)
    }
  }, [activeAddress])

  const handleDetect = (event) => {
    event.preventDefault()
    setSearchMode("geolocation")
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (value && value !== "") {
      setQueryString(value)
      setSearchMode("query")
    }
  }

  return (
    <div className="py-block block-w-container">
      <form className="flex flex-column flex-wrap w-full lg:w-2/3" onSubmit={handleSubmit}>
        <input
          className="w-full md:w-1/2 xl:w-2/3 py-3 px-4 placeholder-gray-300 text-sm text-gray-800 border border-gray-100"
          type="text"
          name="location_query"
          placeholder={t("generic.dealer_map.search_address_placeholder")}
          value={value}
          onChange={handleInput}
        />
        {value && value !== "" && value !== activeAddress ? (
          <div className="w-full md:w-1/2 xl:w-1/3">
            <button
              type="submit"
              name="search-by-text"
              className="btn btn-primary w-full"
            >
              <LocationFilled16 className="inline-block" />&nbsp;{t("generic.dealer_map.search_address")}
            </button>
          </div>
        ) : (
          <div className="w-full md:w-1/2 xl:w-1/3">
            <button
              type="button"
              name="search-by-detection"
              className="btn btn-primary w-full"
              onClick={handleDetect}
            >
              <Compass16 className="inline-block"/>&nbsp;{t("generic.dealer_map.detect_address")}
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default DealerSearch
