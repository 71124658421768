import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

const PromoBlock = ({ block }) => {
  return (
    <>
      {block.image?.filename?.length > 0 && (
        <BlockWrapper
          block={block}
          blockPadding=""
          blockWidth="screen"
          showHeadline="false"
        >
          <div className="relative mx-auto overflow-hidden bg-black md:h-[60vh]">
            <Image
              image={block.image}
              title={block.title}
              alt={block.alt}
              className="object-cover w-full h-auto md:h-full"
              adaptToPixelRatio={true}
            />
            <div className={`inset-0 bg-gray-800 md:absolute md:bg-transparent ${block.no_gradient ? "": "md:bg-gradient-to-r from-black-2/3-transparent to-transparent"}`}>
              <div className="h-full px-4 py-8 mx-auto max-w-screen-2xl">
                <div className="flex flex-col justify-between h-full md:w-1/2 lg:w-2/5">
                  <Headline
                    className="text-white "
                    headlineLevel="h2"
                    headline={block.headline}
                  />
                  <div>
                    <Richtext className="text-white" text={block.text} />
                    {block.buttons.length > 0 && (
                      <div className="mt-8 -mb-4">
                        {block.buttons.map((button, index) => (
                          <Button
                            key={index}
                            className={`mb-4 ${
                              index + 1 < block.buttons.length ? "mr-4" : ""
                            }`}
                            btnType={
                              index + 1 === block.buttons.length
                                ? "primary"
                                : "tertiary"
                            }
                            link={button.link}
                            target={button.target}
                            key={index}
                            externalIcon={button.external_icon}
                            icon={button.icon}
                          >
                            {button.link_text}
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default PromoBlock
