import React from "react"
import Blocks from "../blocks/_Blocks"

const BodyBlocks = ({ body, breadcrumbs, ...rest }) => {
  // console.log("body", body)
  return (
    <div>
      {body &&
        body.map((block) =>
          React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
            breadcrumbs: breadcrumbs,
            ...rest,
          })
        )}
    </div>
  )
}
export default BodyBlocks
