import React, {useState, useEffect} from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { UserAvatar20, UserAvatarFilled20 } from "@carbon/icons-react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import { useTranslation } from "react-i18next"

const loginAssistentUrl = (redirectUri, i18n) => {
  return `${process.env.GATSBY_LOGIN_ASSISTENT_HOST}/${i18n.language}/login?sp_name=${process.env.GATSBY_LOGIN_ASSISTENT_SP_NAME}&website=${process.env.GATSBY_GLOBAL_PATH_PREFIX}&redirect=${redirectUri}`
}


const MediaDatabaseIframeBlock = ({ block }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0()
  const [redirectUri, setRedirectUri] = useState("")
  const { i18n, t } = useTranslation()

  useEffect(() => {
    if (window && window.location && window.location.pathname) {
      setRedirectUri(window.location.pathname)
    }
  }, [])

  //if (isLoading) return null
  if (isAuthenticated) {
    return (<section className="py-block">
      <div className="block-w-container">
        <iframe title="Mediendatenbank" className="w-full h-screen" src={block.iframe_url}></iframe>
      </div>
    </section>)
  } else {
    return (<section className="py-block">
        <div className="block-w-container">
        <div className="p-4 border border-gray-100">
          { t("generic.mediadatabase.loginhint") }
          <a
            className={`btn hover:text-primary`}
            href={loginAssistentUrl(redirectUri, i18n)}
          ><UserAvatar20 className="inline-block -mt-0.5 align-middle" /> { t("generic.login") }</a>
        </div>
        </div>
    </section>)
  }

}

export default MediaDatabaseIframeBlock
