import React from "react"

import { useTranslation } from "react-i18next"

const KlaroConsentInfo = ({ appInfo, handleKlaroConsent, renderWrapper = false}) => {
  const { t } = useTranslation()

  return (
    <>
      { renderWrapper ? (
        <section className="block-w-container py-10">
          <div className="text-left">
            <div className="prose">
              <h3>{t('generic.user_consent.usage_consent_needed')}</h3>
              <p><b>{ appInfo.get("title")}</b>: {appInfo.get("description")}</p>
              <button className="btn btn-primary" onClick={e => {
                handleKlaroConsent(e)
              }}>{t('generic.user_consent.allow_usage')}</button>
            </div>
          </div>
        </section>
      ) : (
        <div className="text-center">
          <div className="prose">
            <h3>{t('generic.user_consent.usage_consent_needed')}</h3>
            <p><b>{ appInfo.get("title")}</b>: {appInfo.get("description")}</p>
            <button className="btn btn-primary" onClick={e => {
              handleKlaroConsent(e)
            }}>{t('generic.user_consent.allow_usage')}</button>
          </div>
        </div>
      )}
    </>
  )
}

export default KlaroConsentInfo
