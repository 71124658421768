import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const JobListingPersonioBlock = ({ block }) => {
  const { i18n, t } = useTranslation()
  const result = useStaticQuery(
    graphql`
      query {
        allPersonioJob {
          nodes {
            department
            employmentType
            id
            jobDescriptions {
              jobDescription {
                name
                value
              }
            }
            name
            occupation
            occupationCategory
            office
            recruitingCategory
            schedule
            seniority
            yearsOfExperience
            fullSlug
          }
        }
      }
    `
  )
  const recruitingCategory = block.recruitingCategory
  const jobs = result.allPersonioJob.nodes.filter(
    (node) => node.recruitingCategory === recruitingCategory
  )

  return (
    <BlockWrapper
      block={block}
      showHeadline="true"
      blockPadding="pt-block pb-10"
    >
      {/* {typeof window !== "undefined" && window.storyblok?.inEditor && (
        <div
          className="py-2 mb-8 rounded px-grid"
          style={{ backgroundColor: "#09b3af" }}
        >
          <p className="font-medium prose text-white max-w-none">
            <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
            <b>Job Listing Personio Block:</b> Falls aus technischen Gründen
            bzw. wegen der gewählten Kategorie keine Personio-Daten vorhanden
            sein sollten, wird dieser Block nicht angezeigt.
          </p>
        </div>
      )} */}
      {jobs?.length > 0 ? (
        <ul
          className={`flex flex-row flex-wrap ${
            block?.headline?.length > 0 ? "mt-8" : ""
          } -mx-grid`}
        >
          {jobs.map((job, index) => (
            <li key={index} className="flex w-full py-2 group md:w-1/2 px-grid">
              <a
                className="flex-1 block p-5 mb-5 border border-gray-100 hover:border-primary"
                href={job.fullSlug}
              >
                <h3 className="font-bold group-hover:text-primary">
                  {job.name}
                </h3>
                <div className="mt-4 text-sm text-gray-400">
                  {t("personio_jobs.schedule." + job.schedule)},{" "}
                  {t("personio_jobs.seniority." + job.seniority)},{" "}
                  <strong>{job.office}</strong>
                </div>
              </a>
            </li>
          ))}
          {!jobs ||
            (jobs.length === 0 && (
              <p>Zur Zeit stehen keine Job-Angebote zur Verfügung.</p>
            ))}
        </ul>
      ) : (
        <div className="mt-8 font-bold">Derzeit sind keine Stellen offen.</div>
      )}
    </BlockWrapper>
  )
}

export default JobListingPersonioBlock
