import React, { useState, useEffect } from "react"
import axios from "axios"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import KlaroConsentInfo from "./KlaroConsentInfo"

import { useTranslation } from "react-i18next"
import useKlaroConsentable from "../../hooks/useKlaroConsentable"

const KLARO_NEXMART_APP_KEY = "nexmart"

const NexmartPanel = ({ sku, retailersFoundCallback, showNoRetailersFoundHint = false }) => {
  const { usageConsented, appInfo, handleKlaroConsent } = useKlaroConsentable(
    KLARO_NEXMART_APP_KEY
  )
  const { t } = useTranslation()

  const [retailers, setRetailers] = useState(null)

  useEffect(() => {
    console.log("DEBUG NexmartPanel usageConsented", usageConsented)
    if (!usageConsented) {
      axios
        .get(process.env.NEXMART_API_URL, {
          auth: {
            username: process.env.NEXMART_USERNAME,
            password: process.env.NEXMART_PASSWORD,
          },
          params: {
            services: "online",
            supplier: process.env.NEXMART_SUPPLIER,
            "pid[1]": sku,
          },
        })
        .then((res) => {
          setRetailers(res.data.retailers)
          if (res.data.retailers && res.data.retailers.length > 0) {
            retailersFoundCallback(true)
          } else {
            retailersFoundCallback(false)
          }
        })
    }

    return () => {
      setRetailers(null)
    }
  }, [sku, usageConsented, retailersFoundCallback])

  if (retailers && retailers.length < 1) {
    if (showNoRetailersFoundHint) {
      return (<div className="p-5">{t("generic.products.no_nexmart_dealers")}</div>)
    } else {
      return null
    }
  }

  return (
    <section className="p-8">
      <div className="flex flex-row flex-wrap -mx-grid">
        {usageConsented === false && appInfo ? (
          <KlaroConsentInfo
            appInfo={appInfo}
            handleKlaroConsent={handleKlaroConsent}
          />
        ) : (
          <>
            {retailers &&
              retailers?.map((retailer, index) => (
                <div className="w-full px-grid lg:w-1/3 mb-8" key={index}>
                  <a
                    href={retailer.products[0].onlineDeeplink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="border-gray-100 hover:border-primary border h-56 flex justify-center items-center p-8"
                  >
                    <Image
                      className="h-44 w-full object-contain"
                      image={retailer.logoUrl}
                    />
                  </a>
                </div>
              ))}
          </>
        )}
      </div>
    </section>
  )
}

export default NexmartPanel
