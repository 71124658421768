import React from "react"
import SbEditable from "storyblok-react"

import { useTranslation } from "react-i18next"

const ProductDataBlock = ({ block }) => {
  const { t, i18n } = useTranslation()
  const currentLang = i18n.language

  return (
    <SbEditable content={block}>
      <section className="py-10 -mb-px border-b border-gray-100">
        <div className="mx-auto max-w-screen-2xl px-grid">
          <div className="flex flex-row flex-wrap -mx-grid mb-8">
            <div className="w-full lg:w-1/4 px-grid">
              <span className="block py-2 font-bold text-primary">
                {t("generic.products.technical_data")}
              </span>
            </div>
            <div className="w-full lg:w-3/4">
              <table className="w-full table-auto">
                <tbody className="text-sm leading-6 align-baseline">
                  {block.techContent?.length > 0 &&
                    block.techContent.map((item, index) => (
                      <React.Fragment key={`product-tech-content-${index}`}>
                        {item.value.Value !== "0,000 X 0,000 X 0,000 " &&
                          item.value.Value !== "0,000 " && (
                            <tr key={index}>
                              <td className="w-1/2 py-2 lg:w-1/3 px-grid">
                                {currentLang === "de"
                                  ? item.label
                                  : item.translated_label}
                              </td>
                              <td className="w-1/2 py-2 lg:w-2/3 px-grid">
                                {!Array.isArray(item.value.Value)
                                  ? item.value.Value
                                  : item.value.Value.join(", ")}
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full lg:w-1/4 px-grid">
              <span className="block py-2 font-bold text-primary">
                {t("generic.products.generic_information")}
              </span>
            </div>
            <div className="w-full lg:w-3/4">
              <table className="w-full table-auto">
                <tbody className="text-sm leading-6 align-baseline">
                  {block.baseData?.length > 0 &&
                    block.baseData.map((item, index) => (
                      <React.Fragment key={`product-base-data-${index}`}>
                        {item.value.Value !== "0,000 X 0,000 X 0,000 " &&
                          item.value.Value !== "0,000 " && (
                            <tr key={index}>
                              <td className="w-1/2 py-2 lg:w-1/3 px-grid">
                                {currentLang === "de"
                                  ? item.label
                                  : item.translated_label}
                              </td>
                              <td className="w-1/2 py-2 lg:w-2/3 px-grid">
                                {item.value.Value}
                              </td>
                            </tr>
                          )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default ProductDataBlock
