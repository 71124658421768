import React from "react"
import SbEditable from "storyblok-react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import RatingStars from "../atoms/RatingStars"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import { ChevronDown16 } from "@carbon/icons-react"

const ProductRatingBlock = ({ block }) => {
  return (
    <SbEditable content={block}>
      <section className="py-10 -mb-px border-b border-gray-100">
        <div className="mx-auto max-w-screen-2xl px-grid">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full md:w-1/2 lg:w-1/4 px-grid">
              <span className="block py-2 font-bold text-primary">
                {block.author}
              </span>
              <span className="block py-2 text-xs text-gray-500">
                Bewertung aus {block.country}
                <br />
                von {block.date}
              </span>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 px-grid">
              <RatingStars rating={block.rating} />
              <span className="ml-1 text-sm">{block.rating}</span>
            </div>
            <div className="w-full lg:w-2/4 px-grid">
              <Headline
                className="mb-4"
                tagName="h3"
                headlineLevel="h4"
                headline={block.headline}
              />

              <Richtext className="prose-sm" text={block.text} />
              <button className="px-0 mt-4 btn">
                Weiterlesen
                <ChevronDown16 className="ml-2 h-6 inline-block -mt-0.25 align-middle" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default ProductRatingBlock
