// Jeder Block muss zweimal hinzugefügt werden.
// 1. Block importieren:
// Theme-Blöcke
import BlockNotFound from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/BlockNotFound"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"
import TextBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TextBlock"
import ImageBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/ImageBlock"
import QuoteBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/QuoteBlock"
import VideoBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/VideoBlock"
import TextVideoBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TextVideoBlock"
import FeaturesBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/FeaturesBlock"
import TeamBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TeamBlock"
import CallToActionBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/CallToActionBlock"
import StatsBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/StatsBlock"
import EmbedBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/EmbedBlock"
import GalleryBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/GalleryBlock"
import FolderBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/FolderBlock"
import DownloadsBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/DownloadsBlock"
import LogoGridBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/LogoGridBlock"
import TableBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TableBlock"
import FaqBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/FaqBlock"
import CardsBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/CardsBlock"
import TextImageBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TextImageBlock"
import RichlinkBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/RichlinkBlock"
import ContentNavBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/ContentNavBlock"
import SubNavBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/SubNavBlock"
import InfoboxBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/InfoboxBlock"
import ArticleListBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/ArticleListBlock"

// Kunden-spezifische Blöcke
// import CountdownBlock from 'gatsby-theme-yum-components/src/components/blocks/CountdownBlock';
import PromoBlock from "../../../../components/blocks/PromoBlock"
import ProductCardsBlock from "../../../../components/blocks/ProductCardsBlock"
import ProductOverviewBlock from "../../../../components/blocks/ProductOverviewBlock"
import ProductFeatureBlock from "../../../../components/blocks/ProductFeatureBlock"
import DividerBlock from "../../../../components/blocks/DividerBlock"
import VariantCardsBlock from "../../../../components/blocks/VariantCardsBlock"
import ProductHeaderBlock from "../../../../components/blocks/ProductHeaderBlock"
import ProductDataBlock from "../../../../components/blocks/ProductDataBlock"
import ProductRatingOverviewBlock from "../../../../components/blocks/ProductRatingOverviewBlock"
import ProductRatingBlock from "../../../../components/blocks/ProductRatingBlock"
import ProductListingPromoBlock from "../../../../components/blocks/ProductListingPromoBlock"
import OxomiBlock from "../../../../components/blocks/OxomiBlock"
import IntroTextBlock from "../../../../components/blocks/IntroTextBlock"
import NexmartBlock from "../../../../components/blocks/NexmartBlock"
import MediaDatabaseIframeBlock from "../../../../components/blocks/MediaDatabaseIframeBlock"
import NewsListingPlaceholderBlock from "../../../../components/blocks/NewsListingPlaceholderBlock"
import JobListingBlock from "../../../../components/blocks/JobListingBlock"
import DealerFinderBlock from "../../../../components/blocks/DealerFinderBlock"
import JobListingFilteredBlock from "../../../../components/blocks/JobListingFilteredBlock"
import BenefitsBlock from "../../../../components/blocks/BenefitsBlock"
import JobListingPersonioBlock from "../../../../components/blocks/JobListingPersonioBlock"
import AnchorBlock from "../../../../components/blocks/AnchorBlock"

// 2. CMS-Block auf React-Block mappen:
const BlocksList = {
  hero_block: HeroBlock,
  text_block: TextBlock,
  image_block: ImageBlock,
  quote_block: QuoteBlock,
  video_block: VideoBlock,
  text_video_block: TextVideoBlock,
  features_block: FeaturesBlock,
  team_block: TeamBlock,
  cta_block: CallToActionBlock,
  stats_block: StatsBlock,
  embed_block: EmbedBlock,
  gallery_block: GalleryBlock,
  folder_block: FolderBlock,
  downloads_block: DownloadsBlock,
  logo_grid_block: LogoGridBlock,
  table_block: TableBlock,
  faq_block: FaqBlock,
  cards_block: CardsBlock,
  text_image_block: TextImageBlock,
  richlink_block: RichlinkBlock,
  content_nav_block: ContentNavBlock,
  sub_nav_block: SubNavBlock,
  infobox_block: InfoboxBlock,
  article_list_block: ArticleListBlock,
  product_cards_block: ProductCardsBlock,
  promo_block: PromoBlock,
  product_overview_block: ProductOverviewBlock,
  product_feature_block: ProductFeatureBlock,
  divider_block: DividerBlock,
  variant_cards_block: VariantCardsBlock,
  product_header_block: ProductHeaderBlock,
  product_data_block: ProductDataBlock,
  product_rating_overview_block: ProductRatingOverviewBlock,
  product_rating_block: ProductRatingBlock,
  product_listing_promo_block: ProductListingPromoBlock,
  oxomi_block: OxomiBlock,
  intro_text_block: IntroTextBlock,
  product_retailers_block: NexmartBlock,
  media_database_iframe_block: MediaDatabaseIframeBlock,
  news_listing_paceholder_block: NewsListingPlaceholderBlock,
  job_listing_block: JobListingBlock,
  job_listing_personio_block: JobListingPersonioBlock,
  dealer_finder_block: DealerFinderBlock,
  job_listing_filtered_block: JobListingFilteredBlock,
  benefits_block: BenefitsBlock,
  anchor_block: AnchorBlock,
}

// Diese Logik kann unverändert bleiben
const Blocks = (type) => {
  if (typeof BlocksList[type] === "undefined") {
    return BlockNotFound
  }
  return BlocksList[type]
}

export default Blocks
