// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   kicker -> text
//   headline -> text / Überschrift
//   subheadline -> text
//   download_items -> bloks / Downloads
// end of automatic

import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFile,
  faFileImage,
  faFileAudio,
  faFilePdf,
  faFileVideo,
  faFileExcel,
  faFileWord,
  faFileArchive,
} from "@fortawesome/pro-solid-svg-icons"

const DownloadsBlock = ({ block }) => {
  let fileExtensions = {
    jpg: faFileImage,
    jpeg: faFileImage,
    png: faFileImage,
    mp3: faFileAudio,
    mp4: faFileVideo,
    pdf: faFilePdf,
    xlsx: faFileExcel,
    xlsm: faFileExcel,
    doc: faFileWord,
    docx: faFileWord,
    docm: faFileWord,
    zip: faFileArchive,
    rar: faFileArchive,
    file: faFile,
  }

  const getIconFA = (file) => {
    if (file) {
      let fileType = file.split(".")
      fileType = fileType[fileType.length - 1]
      if (fileExtensions[fileType]) {
        return fileExtensions[fileType]
      } else {
        return fileExtensions["file"]
      }
    }
  }

  const getFileName = (file) => {
    if (file) {
      let fileName = file.split("/")
      fileName = fileName[fileName.length - 1]
      return fileName
    }
  }

  return (
    <BlockWrapper block={block} blockWidth="container" showHeadline="true">
      <div className="flex flex-row flex-wrap -mx-grid">
        {block.download_items?.length > 0 &&
          block.download_items.map((item, index) => (
            <div className="lg:w-1/2 px-grid" key={index}>
              <div className="flex flex-row mt-8" key={index}>
                <div className="flex">
                  <Link
                    className="inline-block mt-2 mr-4 text-xl font-bold hover:text-primary-hover"
                    link={item.file.filename}
                  >
                    <FontAwesomeIcon
                      icon={getIconFA(item.file.filename)}
                      size="2x"
                      className=""
                    />
                  </Link>
                </div>
                <div>
                  <Link
                    className="block text-xl font-bold text-primary hover:text-primary-hover"
                    link={item.file.filename}
                  >
                    {item.title?.length > 0
                      ? item.title
                      : getFileName(item.file.filename)}
                  </Link>
                  <span className="inline-block prose">{item.description}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default DownloadsBlock
