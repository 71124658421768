import React from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import DealerFinder from "gatsby-theme-rothenberger/src/components/modules/DealerFinder"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

const DealerFinderBlock = ({ block }) => {
  return (
    <BlockWrapper
      block={block}
      blockPadding=""
      blockWidth="screen"
      showHeadline="false"
    >
    { block.headline && (
      <section className="block-w-container">
        <Headline headline={block.headline} />
      </section>
    )}
    <DealerFinder />
    </BlockWrapper>
  )
}

export default DealerFinderBlock
