import React, { useEffect } from "react"
import SbEditable from "storyblok-react"
import { Helmet } from "react-helmet"
import KlaroConsentInfo from "../modules/KlaroConsentInfo"
import useKlaroConsentable from "../../hooks/useKlaroConsentable"
import { useTranslation } from "react-i18next"

const KLARO_OXOMI_APP_KEY = "oxomi"

const OxomiBlock = ({ block }) => {
  const { usageConsented, appInfo, handleKlaroConsent } =
    useKlaroConsentable(KLARO_OXOMI_APP_KEY)
  const { i18n } = useTranslation()

  let nativeLang = i18n.language
  let nativeCountry = process.env.GATSBY_GLOBAL_PATH_PREFIX

  if (nativeLang === "cz") {
    nativeLang = "cs"
  }

  // if (nativeCountry === "bg") {
  //   nativeLang = "ru"
  // }

  if (nativeCountry === "br" || nativeCountry === "tr") {
    nativeLang = "en"
  }

  const oxomi_ready = () => {
    // Initialisierung
    oxomi.init({
      portal: "24510",
      lang: nativeLang,
      country: process.env.GATSBY_GLOBAL_PATH_PREFIX,
    })

    // Funktionsaufruf, um Portaloberfläche anzuzeigen
    oxomi.portalSearch({
      target: "#output",
      input: "#input",
      lang: nativeLang,
      tag: block.filter_tag ? block.filter_tag : "",
      country: process.env.GATSBY_GLOBAL_PATH_PREFIX,
      showDetails: true,
    })
  }

  useEffect(() => {
    if (usageConsented) {
      setTimeout(() => {
        if (oxomi !== "undefined") {
          oxomi_ready()
        }
      }, 1000)
    }
  }, [usageConsented])

  return (
    <>
      {usageConsented === false && appInfo ? (
        <KlaroConsentInfo
          appInfo={appInfo}
          handleKlaroConsent={handleKlaroConsent}
          renderWrapper={true}
        />
      ) : (
        <>
          <Helmet>
            <script
              type="text/javascript"
              src="https://oxomi.com/assets/frontend/oxomi.js"
            ></script>
          </Helmet>
          <SbEditable content={block}>
            <section className="py-4">
              <div className="mx-auto max-w-screen-2xl px-grid">
                {typeof window !== "undefined" && window.storyblok?.inEditor && (
                  <div
                    className="py-2 mb-8 rounded px-grid"
                    style={{ backgroundColor: "#09b3af" }}
                  >
                    <p className="font-medium prose text-white">
                      <h3 style={{ color: "#ffffff" }}>Hinweis:</h3>
                      <b>OXOMI Block:</b> Externe- bzw. Fremdscripte, wie der
                      OXOMI-Code, können unter Umständen aus technischen Gründen
                      in der <i>Vorschau</i> nicht korrekt angezeigt werden.
                    </p>
                  </div>
                )}
                <div id="input"></div>
                <div id="output">loading…</div>
              </div>
            </section>
          </SbEditable>
        </>
      )}
    </>
  )
}

export default OxomiBlock
